import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';

const GuestLayout = () => {
  const { token } = useStateContext();
  if (token) {
    return <Navigate to="/" />
  }
  return (
    <div className='h-screen bg-gray-100 animated fadeInDown'>
        <Outlet/>
    </div>
  )
}

export default GuestLayout