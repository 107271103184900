import React from 'react'

const Header = ({category,title}) => {
  return (
    <div>
      <div className="mb-10">
        <p className="text-gray-400">
          
        </p>
        <p className="text-3xl font-bold tracking-tight text-slate-900">
          {title}
        </p>
      </div>
    </div>
  )
}

export default Header
