import React, { useEffect, useRef } from 'react'
import { Button, Header } from '../../components'
import { GiCancel } from 'react-icons/gi'
import { FaPrint } from 'react-icons/fa'
import Icon from '../../data/icon.png'
import ReactToPrint from 'react-to-print'
const PrintRecipiet = ({paiement,onClose,facture}) => {
    const componentRef=useRef();
    
  return (
    <>
         <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
            <div className='h-screen flex justify-center items-center'>
                <div className='flex-col w-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10  rounded-xl'>
                    <div className=''>
                        <Header title="Reçu de paiement" />
                    </div>
                    <div  ref={componentRef} className="flex flex-col p-4">
                        <div className="flex justify-between gap-3">
                            <div className="flex flex-col gap-3">
                                <h1>Repulic Islamic de Mauritanie</h1>
                                <h1>Ecole privées Ahmed Deina Fall</h1>
                                <h2>Tel: 27111108 41322928</h2>
                            </div>
                            <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
                            <div className="flex flex-col text-right gap-3">
                                <h1>الجمهوريــة الإسلاميــة الموريتـانيــة</h1>
                                <h1>مدارس أحمد دينا افال الحرة</h1>
                                <h2> 27111108 41322928: الهاتف</h2>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-center items-center'>
                                <h1 className='text-lg '>Reçu de paiement</h1>
                            </div>
                            <div className='flex justify-between '>
                                <h2>Moyenne de paiement: {paiement.typePaiement}</h2>
                                <h2>Date: {paiement.datePaiement}</h2>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Elève</th>
                                        <th>Classe</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{facture.inscription.eleve}</td>
                                        <td>{facture.inscription.classe.libelle}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Montant à payer</th>
                                        <th>Montant payée</th>
                                        <th>Reste</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        
                                        <td>{paiement.description}</td>
                                        <td>{paiement.montantInitial}</td>
                                        <td>{paiement.montant}</td>
                                        <td>{paiement.montantReste}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div className='flex  justify-end items-end'>
                        <div className=' flex gap-3 m-2  justify-between'>
                            <ReactToPrint
                                trigger={()=>{
                                    return <Button icon={<FaPrint />}  color="ml-1 bg-green-500" textColor="text-white" text="Imprimer" />
                                }}
                                content={()=>componentRef.current}
                                documentTitle='Recipiet'
                                pageStyle='print'
                            />
                            <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default PrintRecipiet