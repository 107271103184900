import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const AddSortie = ({ isVisible = true, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
   
    
    
    const [sortie, setSortie] = useState({
        montant: 0,
        dateSortie:'',
        titre:'',
        type:'',
        ref:'',
        not:'',
    });

   
    const navigate = useNavigate();
    
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/sortie/save', sortie)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    
    if (!isVisible) {
        return null
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header title="Ajoute un sortie" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        {loading ? <Loading /> :
                            <>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                    <div className='flex-col w-full justify-evenly '>
                                        <div className='mr-2'>
                                            <Input label="Montant" type="number" value={sortie.montant}  onChange={ev => setSortie({ ...sortie, montant: ev.target.value })} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Type" type="text" value={sortie.type}  onChange={ev => setSortie({ ...sortie, type: ev.target.value })} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Titre" type="text" value={sortie.titre}  onChange={ev => setSortie({ ...sortie, titre: ev.target.value })} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Ref" type="text" value={sortie.ref}  onChange={ev => setSortie({ ...sortie, ref: ev.target.value })}  />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Note" type="text" value={sortie.note}  onChange={ev => setSortie({ ...sortie, note: ev.target.value })}  />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSortie