import React, { useEffect, useState } from 'react'
import { Button, Header } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setToken } = useStateContext();
  const getUsers = () => {
    axiosClient.get('/users')
      .then((data) => {
        setUsers(data.data.users);
        setLoading(false);
        
      }).catch(err => {
        const response=err.response;
        if (err.response.status === 401) {
          setToken(null);
        }else{
            if(response.status===401)
            {
              setToken(null);
            }
            else{
              if(response.status===403){
                navigate('/403');
              }else{
                if(response.status===404){
                  navigate('/404');
                }
                else{
                  navigate('/500');
                }
              }
            }
          }

      })
  }

  useEffect(() => {
    getUsers();
  }, []);
  
  return (
    <>
      <Header category='Page' title='Users' />
      <div className='flex-col '>
        <div className='items-end'>
          <Link to='/user/new'>
            <Button color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
          </Link>
        </div>
        <div className='mt-3 w-full overflow-x-auto'>
          {loading ? <Loading /> :
            (
              <table className='table-auto'>
                <thead>
                  <tr>
                    <th >Username</th>
                    <th>Nom</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users && users.map(o => (
                    <tr key={o.id}>
                      <td>{o.username}</td>
                      <td>{o.nom}</td>
                      <td>{o.role && o.role.name}</td>
                      <td>
                        <div className="flex justify-evenly">
                          <Link to={`/user/${o.id}`}>
                            <ActionButton color="bg-green-500" icon={<AiTwotoneEdit />} textColor="text-white" title="Modifier" />
                          </Link>
                          <Link to="#">
                            <ActionButton color="bg-red-500" icon={<BsTrash/>} textColor="text-white" title="Supprimer" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              </table>
            )

          }
        </div>
      </div>
    </>
  )
}

export default Users