import React from 'react'
import icon from '../data/icon.png';
const Home = () => {
  return (
    <div>
      <div className='flex h-screen p-48 justify-center items-center '>
        <div className='animate-pulse'>
          <img className="mx-auto h-auto w-auto" src={icon} alt="Your Company" />
        </div>
      </div>
    </div>
  )
}
export default Home